<template>
	<PopupLayer
	>
		<template
			v-slot:body
		>
			<div
				class="width-480 ma-auto  flex-column justify-space-between bg-white"
			>
				<div
					class="under-line justify-space-between items-center pa-10-20 "
				>
					<h6>입출금 상세 정보</h6>
					<button
						@click="$emit('cancel')"
					><mdicon name="close-circle" /></button>
				</div>
				<div
					class="full-height flex-column pa-20 overflow-hidden"
				>
					<table class="table td-right">
						<colgroup>
							<col width="120px" />
							<col width="auto" />
						</colgroup>
						<tbody>
						<tr>
							<th>대리점</th>
							<td>{{ item.agency_name }}</td>
						</tr>
						<tr>
							<th>아이디</th>
							<td>{{ item.account_id }}</td>
						</tr>
						<tr>
							<th>이름</th>
							<td>{{ item.member_name }}</td>
						</tr>
						<tr>
							<th>전송구분</th>
							<td>{{ item.send_type_name }}</td>
						</tr>
						<tr>
							<th>입출금 구분</th>
							<td :class="'color-' + item.history_type_color">{{ item.history_type_name }}</td>
						</tr>
						<tr>
							<th>승인상태</th>
							<td :class="'color-' + item.status_color">{{ item.status_name }}</td>
						</tr>
						<tr>
							<th>금액</th>
							<td :class="'color-' + item.history_type_color">{{ item.amount | makeComma }}원</td>
						</tr>
						<tr>
							<th>수수료</th>
							<td>{{ item.fee | makeComma}} 원</td>
						</tr>
						<tr>
							<th>보내는 계정</th>
							<td>{{ item.bank_depositor }}</td>
						</tr>
						<tr>
							<th>받는 계정</th>
							<td>{{ item.bank_holder }}</td>
						</tr>
						<tr>
							<th>메모</th>
							<td :inner-html.prop="item.memo | nl2br"></td>
						</tr>
						<tr>
							<th>처리 일자</th>
							<td>{{ item.wDate }}</td>
						</tr>
						<tr>
							<th>TID</th>
							<td>{{ item.tid }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	components: {PopupLayer}
	, name: 'MemberWalletDetail'
	, props: ['user', 'item']
	, data: function(){
		return {

		}
	}
}
</script>